.recomended {
  color: #000;
  font-family: Poppins;
  font-size: 33px;
  font-weight: map-get($font-weights, "bolder");
  height: 50px;

  letter-spacing: 0.44px;
  line-height: 49px;
  position: absolute;
  top: 643px;
  width: 500px;
}

p {
  margin: 0 !important;
}



.hotel-wrapper {
  margin-top: 35px;
  margin-bottom: 12px;

  h1 {
    font-family: Poppins;
    font-weight: 900;
    text-transform: capitalize;
  }

  p {
    font-family: Poppins;
    font-size: clamp(12px, 0.9375vw, 18px);
  }
}

.restau-wrapper {
  margin-top: 35px;
  margin-bottom: 12px;

  h1 {
    font-family: Poppins;
    font-weight: 900;
    text-transform: capitalize;
  }

  p {
    font-family: Poppins;
    font-size: clamp(12px, 0.9375vw, 18px);
  }
}

.search-component {
  .filter-results {
    margin-top: 60px;
  }

  h3 {
    font-family: Poppins;
    font-weight: 900;
    font-size: 28px;
    margin: 25px 0 25px 0;
  }
}

.search-drawer {
  margin-top: 70px;

  .swiper-wrapper {
    gap: 1rem !important;
  }
}

.search-slider {
  .swiper-container {
    width: 100% !important;

    .swiper-wrapper {
      height: 100% !important;
    }
  }
}

@media (max-width: 768px) {
  .search-component {
    .filter-results {
      font-size: 15px;
    }

    .mobile-title {
      font-family: Poppins;
      font-weight: 900;
      font-size: 20px;
      margin-bottom: -15px;
      margin-top: 13px;
    }
  }

  .hotel-wrapper {
    h1 {
      font-family: Poppins;
      font-weight: 900;
      font-size: 18px;
      text-transform: capitalize;
    }

    p {
      font-family: Poppins;
      font-size: 10px;
    }
  }

  .restau-wrapper {
    h1 {
      font-size: 18px;
    }

    p {
      font-size: 10px;
    }
  }

  .search-drawer {
    h1 {
      font-size: 15px;
    }
  }

  .search-drawer-nomargin {
    h1 {
      font-size: 15px;
    }
  }
}
