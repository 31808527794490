.poinavi {
    @include row(30px);
    align-items: center;
    border-radius: 10px;
    font-family: Poppins;
    padding: 5px;
    top: 50px;
    width: 56px;
    p {

color: #0185ff;
font-size: 13px;
font-weight: map-get($font-weights, "medium");
line-height: 110%;
}

img {
margin-left: 32%;
margin-top: 30%;
}
}

.likes {
    @include row(10px);
    margin-top: 10px;
    img {
    margin-left: 32%;
margin-top: 30%;
}
}

.poitabs {
    @include column(10px);
    align-items: center;
    border-radius: 10px;
    font-family: Poppins;
    padding: 5px;
    top: 50px;
    width: 56px;
}
