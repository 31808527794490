#city-swiper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px !important;

  .swiper-wrapper {
    height: fit-content;
    width: fit-content;
  }
}

.city-card {
  font-weight: 900;
  font-family: Poppins;
  text-transform: capitalize;

  font-size: clamp(12px, 0.9375vw, 18px);
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  align-items: center;
  justify-content: space-around;
  background: #fcc100;
  padding: 10px 0px;
  padding-left: 20px;
  padding-bottom: 30px !important;

  span {
    position: relative;
    bottom: 3px;
  }
}

.news {
  video {
    width: 96% !important;
    border-radius: 20px;
  }

  .center {
    text-align: center;
  }

  [type="checkbox"] {
    display: inline-block;
    opacity: 0;
    position: absolute;
  }

  .input {
    width: 97%;
    background: rgba(0, 0, 0, 0.03) !important;

    height: 60px;
    border-radius: 36.5468px;

    &::placeholder {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #262626;
      opacity: 0.24;
    }
  }
}
