.poinavigation {
  @include row(80%);
  margin-top: 150px;

  .poinavi .poiIcon {
    border: 1px solid #0185ff;
    border-radius: 10px;
    filter: $drop-shadow;
    height: 56px;
    width: 56px;

    &:hover {
      cursor: pointer
    }
  }

  .likes .poiIcon {
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    height: 56px;
    width: 56px;

    &:hover {
      cursor: pointer
    }
  }

  .poidesc {
    color: #383874;
    font-family: Poppins;
    font-size: 16px;
    font-weight: map-get($font-weights, "light");
    letter-spacing: 0.44px;
    line-height: 24px;
    width: 100%;
  }

  .poinImages .wrapper {
    @include grid(30px, 20px, 15vw);
    border: 0;
    font-family: Poppins;
    height: 400px;
    width: 30%;
  }
}

.poibtn {
  background: transparent;
  border: 0;
  color: #383874;
  font-family: Poppins;
  font-size: 16px;
  font-weight: map-get($font-weights, "bold");
  letter-spacing: 0.44px;
  line-height: 24px;
  margin: 0;
  padding: 0;
}

@media (max-width: 1500px) {
  .poinavigation {
    @include row(62%);
  }

  .poinavi {
    gap: 15px !important;
  }
}