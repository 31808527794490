.gplaces-autocomplete {
    ul {
        position: absolute;
        z-index: 9999999999;
        margin: 0;
        width: 100%;
        max-width: 350px;
        padding: 0;
    }

    li {
        list-style: none;
        border: 1px solid #fcc100;
        
        margin-left: 0;
        background-color: white !important;
        padding: 10px;
        color: black;
    }
}