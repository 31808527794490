.checkbox-tag-checked,
.checkbox-tag {
  cursor: pointer;
  display: block;

  font-weight: 900;
  font-family: Poppins;
  text-transform: capitalize;

  width: 100%;
  font-size: clamp(12px, 0.9375vw, 18px);
  background: rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  justify-content: center;
  background: #fcc100 !important;
  padding: 20px 0px;
  position: relative;

  .crop-text {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  label {
    padding-top: 0.3vw;
    padding-left: 10px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    input {
      width: 100%;
      height: 100%;
      left: 0;
      cursor: pointer;
    }
  }

  img {
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    right: 10px;
  }
}

.checkbox-tag {
  background: rgba(0, 0, 0, 0.03) !important;
  font-weight: 500 !important;

  :active {
    font-weight: 900;
  }
}

.useful-info {
  width: 80%;
  display: flex;
  padding: 24px 32px;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border-radius: 26px;
  background: #ffdf01;

  h2 {
    color: #000;
    font-family: Poppins;
    font-size: 33px;
    font-style: normal;
    font-weight: 900;
    line-height: 39.6px; /* 120% */
    letter-spacing: 0.44px;
  }

  p {
    color: #212529;
    font-family: Tifinaghe-Ircam Unicode;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px; /* 120% */
  }
}

.Home-customize-form {
  background-color: transparent;
  border: none;
  height: 100%;
  padding: 0;
  width: 100%;
  cursor: pointer;
  display: inline-block;

  p {
    font-family: Poppins;
    font-size: 18px;
  }

  h1 {
    font-family: Poppins;
    font-weight: 900;

    width: 13vw;
    margin-bottom: 20px;
  }

  .check-elements {
    display: flex !important;
    flex-flow: row;
    width: 90%;
    justify-content: space-between;
  }

  h3 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    margin-top: 21px;
  }

  [type="checkbox"] {
    &:not(:checked) {
      display: inline-block;
      opacity: 0;
      position: absolute;
    }
  }

  [type="radio"] {
    &:not(:checked) {
      left: 0;
      opacity: 0.01;
      position: absolute;
    }
  }

  .i,
  .i + .checkbox-tag-checked {
    padding-left: 15px;
    width: 100% !important;
    display: block;
  }

  .active {
    background: #fcc10083 !important;
  }
}

.Home-customize-form.region {
  p {
    color: #212529;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5125rem; /* 172.857% */
    opacity: 0.7;
  }
}

.search-component {
  [type="checkbox"] {
    &:not(:checked) {
      display: inline-block;
      opacity: 0;
      position: absolute;
    }
  }
}

.form-check {
  width: 9.375vw;
  border-radius: 12px;
  height: 3vw;
  display: inline-block;
  padding: 5px;
  font-family: Poppins;
  text-transform: capitalize;
  background: #f7f7f7;
  font-size: 1vw;
  padding-top: 13.02vw;

  .form-check-label {
    position: relative;
    z-index: 98;
    margin-left: 1.3vw;
    bottom: 40px;
    font-family: Poppins;
    font-weight: 900;
    font-size: clamp(12px, 0.83vw, 18px);
  }

  .form-check-input {
    cursor: pointer;
    width: 10.05vw;
    height: 13.02vw;
    position: absolute;
    bottom: 30px;
    z-index: 99;
    font-size: clamp(12px, 1vw, 18px);
    background: rgba(0, 0, 0, 0.03);
    border-radius: 12px;
  }
}

.Home-customize-form-small {
  width: 100% !important;
}

[type="checkbox"] {
  &:checked {
    opacity: 0;
    position: absolute;
  }
}

[type="radio"] {
  &:checked {
    left: 0;
    opacity: 0.01;
    position: absolute;
  }
}

.ant-radio-button-wrapper-checked {
  transition: all 1s;

  &:not(.ant-radio-button-wrapper-disabled) {
    background-color: #1890ff;
    color: #ffffff;
  }
}

.circuit .Home-customize-form {
  margin-top: -20px !important;
}

.topdesti .checkbox-tag-2 {
  p {
    font-family: Poppins;
    font-size: clamp(12px, 0.41vw, 16px) !important;
    margin: -10px 0 0 30px !important;
  }

  h5 {
    font-family: Poppins;
    font-weight: 900;
    font-size: clamp(12px, 0.9375vw, 18px);
    margin-left: 30px;
    margin-top: 15px;
  }
}

.topdesti {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .swipers-section {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    .items {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
  .swiper-wrapper {
    gap: 1rem !important;
  }
  .swiper-slide {
    width: fit-content !important;
    height: fit-content !important;
  }
  .tab-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .sub-bar {
    background: #262626;
    border-radius: 20px;
    padding-top: 40px;
    padding-left: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    width: 96.5%;
    position: relative;
    z-index: 4;
    margin-top: -80px;

    .dots-place-holder {
      font-family: Poppins;
      font-size: 10px !important;
      color: white;
    }

    h4 {
      font-family: Poppins;
      font-weight: bold;
      font-size: 10px;
      color: white;
    }

    .cities-container {
      display: flex;
      flex-direction: column;
    }
  }
}

.topdesti.best_places {
  p {
    color: #212529;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5125rem; /* 172.857% */
    opacity: 0.7;
  }
}

.nav-tabs .nav-link,
.nav-tabs .nav-link {
  border: none !important;
}

.nav-tabs .nav-link {
  padding: 25px 0px 34px 25px !important;

  p {
    margin-top: -8px !important;
  }
}

.tripTabs-home {
  display: flex;
  flex-flow: row;
  border-radius: 16px;
  justify-content: center;
  align-content: center;
  width: 100%;
  position: relative;
  z-index: 98;
  padding-top: 12px;
  padding-bottom: 0px;
  background-color: #fcc100;

  .hidden {
    visibility: hidden !important;
  }

  .nav-link.active {
    padding: 25px 0px 0px 25px;
    margin-top: 0;
    height: 85px;
    background-color: rgba(255, 223, 1, 0.5) !important;
    transition: all 0.2s;
    border-radius: 16px;
  }

  .checkedtrip {
    cursor: pointer;
    display: flex;
    flex-flow: row;
    font-family: Poppins;
    text-transform: capitalize;
    width: 50%;
    height: 60px;
    font-size: 16px;
    border-radius: 40px;
    background: #fcc100 !important;

    p {
      font-family: Poppins;
      font-size: 8px !important;
      margin: -10px 0 0 30px !important;
    }

    h5 {
      font-family: Poppins;
      font-weight: 900;
      font-size: 18px;
      margin-left: 30px;
      margin-top: 50px;
    }
  }
}

/*Extra small devices*/
@media only screen and (max-width: 768px) {
  .Home-customize-form .i,
  .Home-customize-form .i + .checkbox-tag-checked {
    width: 46% !important;
  }

  .Home-customize-form {
    width: 100%;
    margin-top: 12px;
  }

  .topdesti {
    h1 {
      font-size: 26px;
      width: 100%;
    }

    p,
    h5 {
      font-size: 10px;
    }

    .desc {
      font-size: 4.4px;
    }

    .tab-title {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    .img {
      width: 200px;
      height: 22px;

      .recherche {
        font-size: 8px;
        padding-top: 10px;
      }

      img {
        width: 8px;
      }
    }

    .sub-bar {
      width: 92%;
      position: absolute;
      z-index: 4;
      margin-top: -10px;
    }
  }

  .search-tag {
    width: 95% !important;
    height: 25px !important;
    justify-content: center;
    align-items: center;
    display: flex !important;

    font-size: 8px !important;
    border-radius: 28px !important;
  }
}
