  .filter {
      text-align: center;
      color: white;
      background-color: rgba(0, 0, 0, 0.45);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
  }

  .deals {
      margin-top: 40px;
      width: 96.8%;

      .swiper {
          width: 96.6%;

          img {
              width: 96.6%;
          }
      }

      h1 {
          margin-bottom: 24px;
          font-family: Poppins;
          font-weight: 900;

          margin-bottom: 25px;
      }

      .filter {
          text-align: center;
          color: white;
          background-color: rgba(0, 0, 0, 0.45);
          width: 100%;
          height: 21vw;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          position: absolute;
          z-index: 88
      }

      h4 {
          width: 50%;
      }

      .swiper-wrapper {
          height: 22vw !important;
      }

      .deals-arrows {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          position: relative;
          left: -10px;

          button {
              border: none;
              background: none;
          }

          &__btn {
              position: relative;
              left: 15px;
          }
      }

      .swiper-pagination {
          position: absolute;
          width: 40%;
          marginLeft: 30%
      }
  }

  @media (max-width: 768px) {
      .deals {
          h1 {
              font-size: 20px
          }
      }

      .deals-arrows {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          gap: 40vw;
      }

      .swiper-pagination {
          position: absolute;
          width: 100%;
          margin-left: 0%
      }
  }