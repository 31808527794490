.uppernav-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  background: rgba(38, 38, 38, 0.04);
  width: 58vw;
  left: -50px;
  height: 45px;
}

@media (max-width: 768px) {
  .uppernav-bg {
    width: 100vw;
    top: 0;
    left: 0;
  }
}


.nav {
  height: 20px;
  width: 100%;

  .nav-header {
    display: inline;
  }

  .nav-links {
    display: inline;
    justify-content: space-between;
    @include row(1%);
    position: relative;
    width: 100%;
    height: 55px;
    align-items: center;

    a,
    button {
      color: #000000;
      background-color: transparent;
      display: inline-block;
      font-family: Poppins;
      font-size: clamp(10px, 1.3vw, 25px) !important;
      font-weight: map-get($font-weights, "bolder");

      padding: 20px 10px 13px 10px;
      text-decoration: none;

      &:hover {
        color: rgba(0, 0, 0, 0.603);
        text-decoration: underline;
        text-decoration-color: #ffe711;
      }

      .humburger {
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        display: inline-block;
        height: auto;
        background-color: transparent;
        border: none;
        position: relative;
        top: 0px;
        width: 60px;
        height: 69px;
        transition: all .3s ease-in-out;

        &:hover {
          transform: scale(1.2);
        }
      }

    }


  }


  .login-icon {
    border-radius: 5px;
    height: 38px;
    position: relative;
    width: 38px;
    background-color: #F7F7F7;
  }

  .signin {
    background-color: black;
    height: 30px;
    border-radius: 5px;

    h4 {
      color: white;
      padding: 12px;
      font-size: 12px;
    }
  }
}

.nav>.nav-header {
  display: inline;
}

.nav>.nav-header>.nav-logo {
  color: #fff;
  display: inline-block;
  font-size: 22px;
  padding: 10px 10px 10px 10px;
  width: 7vw
}

.nav-flexstart {
  right: 20px;
  position: relative;
  gap: 20px !important;

  .modal {
    padding: 0 !important;
  }

  .modal-dialog {
    margin: 0;
  }

  .modal-content {
    height: 100vh;
    width: 100vw;
  }
}

.btn.btn-light {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent !important;
  gap: 9px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  padding-top: 10px;
}


.nav-flexstart {
  @include row(3.5vw);
  padding-top: 5px;
  align-items: center;
}

.tn-nav{
  margin-top: 50px;
  margin-bottom: 0;
  margin-left: 20px;
}

.dropdown-toggle::after {
  display: none !important;
}

.upper-nav {
  width: 104.5% !important;
  height: 44px;
  position: relative;
  z-index: 99;
  display: block;
  margin-bottom: 20px;


  .col-md-4 {
    display: flex;
    flex-direction: row;



    align-items: center;
    gap: 12px;


    position: relative;
    top: 2px;
  }


  .row {
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }



  .col {

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: fit-content;



    img {
      width: 14px;
      height: 14px;
    }
  }

  .uppernav-name {
    font-family: Poppins;
    -webkit-font-smoothing: subpixel-antialiased;
    font-weight: 500;
    font-size: clamp(10px, 0.8vw, 16px) !important;
    margin: 0;
    // capitalize
    text-transform: capitalize !important;

  }


}

#dropdown-language-switcher {
  background-color: transparent;
  border: unset;
  outline: unset;
  margin: 0;
  padding: 0;

  .uppernav-name {
    display: inline-block;
    padding-bottom: 0;
  }


}

@media (max-width: 768px) {
  .dropdown-menu {
    left: unset;
    right: -10px;
  }
  #dropdown-language-switcher {
    .uppernav-name {
      display: inline-block;
      color: white;
      padding-bottom: 0;
    }
  }

}

@media (max-width: 1366px) {
  .nav {
    width: 100%;
  }

  a {
    font-size: 19px !important;
  }

  .nav-flexstart {
    @include row(2vw)
  }
}


@media(max-width:768px) {

  .upper-nav,
  .nav,
  .uppernav-bg {
    display: none !important;
  }
}