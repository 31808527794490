.leftside1,
.leftside2 {
  position: relative;
  z-index: 40;
  width: 48vw;
  // border-bottom-right-radius: 30px !important;
  // border-top-right-radius: 30px !important;

  > div {
    > div {
      &:nth-child(1) {
        width: 89% !important;
        left: -10px;
        margin-top: 30px;
        margin-left: 42px;
      }

      &:nth-child(2) {
        width: 100% !important;
        height: 100vh !important;
        // border-bottom-right-radius: 30px;
        // border-top-right-radius: 30px;

        img {
          &:not(.arrowstory > img) {
            max-width: 100%;
            max-height: 100vh;
            margin: auto;
            width: 1000px;
            height: 100vh;
          }
        }
      }

      &:nth-child(3) {
        width: 100% !important;

        div {
          &:first-child {
            position: absolute !important;
            height: 40px !important;
            width: 40px !important;
            top: 52%;
            left: 30px;
            background: url("../../images/arrowLeftWhite.svg") no-repeat center /
              contain;
            cursor: pointer;
          }

          &:last-child {
            position: absolute !important;
            height: 40px !important;
            width: 40px !important;
            top: 52%;
            right: 40px;
            background: url("../../images/arrowRightWhite.svg") no-repeat center /
              contain;
            cursor: pointer;
          }
        }
      }
    }

    width: 100% !important;
    // border-bottom-right-radius: 30px;
    // border-top-right-radius: 30px;
  }

  > div {
    height: 100vh !important;
  }

  canvas {
    height: 100vh !important;
    width: 48vw !important;
    // border-bottom-right-radius: 30px;
    // border-top-right-radius: 30px;
  }
}

.pulsating-circle-day {
  color: white;
  z-index: 99999999999999;
  font-size: 20px;
  line-height: 40px;
  font-weight: 100;
  width: 40px;
  height: 40px;
  margin-top: 15px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #01a4e9;
  text-align: center;
  border: 2px solid white;
}

#example-collapse-text {
  position: relative;
  z-index: 999999;

  .other {
    .swiper-wrapper {
      width: 100%;
      height: 100%;
    }

    .swiper-container {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      width: auto;
      min-width: 200px;
      height: 100%;
    }

    .swiper-grid-arrows {
      position: relative;
      z-index: 99999999999;

      button {
        margin: 0;
        position: relative;
        z-index: 99999999999;
      }
    }
  }
}

.leftsidemap {
  height: 100% !important;
  width: 48vw !important;
  position: relative;

  .map-miges {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }

  canvas {
    // border-bottom-right-radius: 30px;
    // border-top-right-radius: 30px;
  }
}

#mb-remove-destination {
  .mapbox-directions-destination,
  .directions-icon-reverse {
    display: none !important;
  }
}

.mapbox-marker {
  position: relative !important;
}

.pageTitle {
  font-family: Poppins;
  font-size: 33px;
  font-weight: map-get($font-weights, "bolder");
  height: 50px;
  line-height: 49px;
  position: absolute;
  top: 120px;
}

.infos-wrapper {
  background-color: #fffdf5;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}

.leftsideinfo {
  background-color: #fffdf5;
  // border-bottom-right-radius: 30px !important;
  // border-top-right-radius: 30px !important;
  width: 48vw !important;
  height: 100vh !important;
  overflow: hidden !important;
}

.leftside1 {
  transition: all 0.4s;
  position: relative;
}

.mapbtn {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-family: Poppins;
  font-style: normal;
  color: white;
  font-weight: 500;
  font-size: clamp(16px, 1vw, 20px);
  line-height: 110%;
  background: #174793;
  border-radius: 20px;
  border: none;
  align-items: center;
  padding: 20px 30px;
}

@media (max-width: 768px) {
  #left {
    display: none !important;
  }

  .leftside1 > div > div {
    height: 100vw !important;
  }

  .leftside1 > div {
    border-top-right-radius: 0px !important;
    // border-bottom-right-radius: 30px !important;
    // border-bottom-left-radius: 30px !important;
    height: 90vw !important;
  }

  .leftside1 > div > div:nth-child(1) {
    height: 50vh !important;
  }

  .leftside1 > div > div:nth-child(2) {
    height: 50vh !important;
    border-top-right-radius: 0px !important;
    // border-bottom-right-radius: 30px !important;
    // border-bottom-left-radius: 30px !important;
  }

  .leftside1 > div > div:nth-child(3) {
    height: 60vh !important;
  }
}
