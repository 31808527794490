#navmodal {
    width: 100%;
    margin: 0;

    .modal-content {
        width: 100vw;
        height: 100vh;
        overflow-x: hidden !important;
        overflow-y: auto;
        background-color: #26262670 !important;

        .modal-body {
            color: white;
            margin-top: 23px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .row {
                gap: 3rem;
            }
           
        }

        ul {
            list-style-type: none;
            padding: 0;
        }

        li {
            span {
                font-family: Poppins;
                font-weight: 500;
                font-size: clamp(12px, 0.625vw, 25px);
                position: relative;
                bottom: 10px;
            }
            padding: 0;
            font-family: Poppins;
            font-style: normal;
            font-weight: 900;
            font-size: clamp(18px, 2.08vw, 40px);
            cursor: pointer;
            text-decoration: none;
            margin-top: 50px;
        }

        p {
            color: #FCC100;
            font-family: Poppins;
            font-weight: 500;
            font-size: clamp(12px, 1.04vw, 25px);
            margin-bottom: 71px;
        }

        .vertical {
            border-right: 1px solid #FCC100;
        }

    }
}

@media (max-width: 768px) {
    #navmodal {
        .modal-body{
            justify-content: flex-start !important;
            align-items: flex-start !important;
        }
    }

    #navmodal .modal-content {
        width: 100vw;
        height: 150vh;
        background-color: #262626 !important;

    }

    #navmodal .vertical {
        border-right: none !important;
        text-align: left !important;
    }

    #navmodal .col p {
        padding-left: 0px !important;
    }

    #navmodal ul {
        padding-left: 0px !important;
    }

    #navmodal ul li {
        margin-left: 0px !important;
    }
}