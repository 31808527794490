.singleExp-container {
    margin-top: 30px;

    .display-text-group {
        width: 90% !important;
        line-height: 180% !important;

        .displayed-text {
            color: black !important;
            text-align: justify !important;

        }

        .read-more-button {
            font-weight: 900;
            padding-top: 20px;
            cursor: pointer;
        }
    }




    #panel {
        width: 600%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        gap: 350px
    }

    .pagination-num {
        color: black;
        border-radius: 70px;
        background-color: #F1A300;
        display: flex;
        justify-content: center;
        width: 30px;
        margin-left: 50%;
        margin-top: 20px;
        display: fixed;
    }



    .singleExp {
        margin-top: 24px;

        h2 {
            color: white !important;
        }

        h1 {
            margin: 15px 0 15px 0;
            font-family: Poppins;

            font-weight: 100 !important;

            line-height: 48px;
        }

        :nth-child(1) {
            color: #F1A300;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: clamp(16px, 1.04vw, 18px);
        }

        :nth-child(3) {
            font-family: Poppins;
            font-weight: 500;
            font-size: 18px;
            line-height: 200%;
        }

        button {
            border: none;
            font-family: Poppins;
            font-weight: 500;
            font-size: 14.38px;
            position: relative;
            right: 10px;
            background-color: transparent;
            color: #386CB9;
            margin-top: 24px;
        }


    }
}

.expinfo {
    p {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        color: #000000;
    }


}

.read-more-button {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500 !important;
    font-size: clamp(12px, 1vw, 20px) ;
    line-height: 140%;
    color: #386CB9
}

.nav-tabs {
    height: 55px;
    justify-content: space-between;


    .img {
        width: 38px !important;
        height: 38px;
        gap: 3px;
        background: black;
        color: white;
        border-radius: 100px !important;
        display: flex;
        padding: 12px;
        position: relative;
        bottom: 5px;
        margin-right: 23px;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        p {
            margin: 0px !important;
            font-size: 15px !important;
        }
    }

    img {
        width: 16px;
        height: 16px;

    }

    .nav-link {
        width: 50%;
        border: none;

        &:hover {
            border: none;
        }
    }

}

.tab-pane {
    .Home-customize-form {
        margin-top: 0px !important;
    }
}

.elementtrip {
    h5 {
        font-family: Poppins;
        font-weight: 900;
        font-size: clamp(14px, 1.1vw, 18px);
        color: black;
    }

    p {
        font-family: Poppins;
        font-size: 8px;
        margin: 0 !important;
        padding-bottom: 10px;
        color: black;
    }
}

.route-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: -50px !important;

    a {
        color: #212529;
        text-transform: capitalize;
        font-size: clamp(14px, 0.8vw, 18px) !important;
    }

    .route {
        opacity: 0.24;
        font-size: clamp(14px, 0.8vw, 18px) !important;
    }

    span {
        font-size: clamp(14px, 0.8vw, 18px) !important;
    }
}

.expand-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid black;
    position: relative;
    z-index: 9999999999;
    padding: 15px;
    padding-bottom: 10px;
    margin-top: 50px;
    cursor: pointer;

    h3 {
        color: black !important;
        padding-top: 15px !important;
    }

}

.route-container {
    margin-top: 0px !important;
}

.directionInput {
    &::placeholder {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
    }
}

@media (min-width: 1280px) and (max-width: 1400px) {
    .nav-tabs .img {
        width: 43%;
    }
}

@media (min-width: 1440px) and (max-width: 1900px) {
    .nav-tabs .img {
        width: 40%;
    }
}


@media (min-width: 1900px) {
    .nav-tabs .img {
        width: 30%;
    }
}

@media only screen and (max-width: 600px) {
    .route-container {


        .route,
        span {
            font-size: 10px;
        }
    }

}