#endregion {
  width: 97%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(201px, 1fr));
  grid-template-rows: 1fr 1fr;
  row-gap: 10px;
  column-gap: 10px;
  padding: 0px 5px;

  @media (min-width: 1000px) and (max-width: 1400px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.tab {
  @include row(30px);
  overflow: hidden;
  border: 1px solid transparent;
  background-color: transparent;
  color: #473f3f;
  margin-top: 140px;
  width: 60%;
  font-family: Poppins;

  button {
    background-color: inherit;
    float: left;
    border: 0;
    outline: none;
    cursor: pointer;
    padding: 0;
    transition: 0.2s ease-in-out;
    border-bottom: 5px solid transparent;
    font-size: 15px;
    line-height: 110%;
    color: #473f3f;

    &:hover {
      border-bottom: 5px solid #1381fe;
      font-weight: bolder;
    }
  }

  button.active {
    border-bottom: 5px solid #1381fe;
    font-weight: bolder;
  }
}

.tabcontent {
  display: none;
  padding: 0 12px;
  width: 98%;
  border: 0;
  border-top: 0;
  height: 100%;

  #text {
    margin-top: 0 !important;
  }

  .hrcontainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    hr {
      background-color: black !important;
      width: 100%;
      margin-right: 50px;
    }
  }

  button {
    background: transparent;
    color: #386cb9;

    width: fit-content;
    height: 60px;
    border: 0;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    font-family: Poppins;
    font-weight: 300;
    text-decoration: underline !important;
    font-size: clamp(14px, 0.83vw, 18px);
  }

  @media (max-width: 762px) {
    button {
      font-size: 15px;
    }

    #text {
      font-size: 14px !important;
    }
  }

  h3 {
    font-size: clamp(14px, 0.9375vw, 18px) !important;
    line-height: 140% !important;
  }
}

.rtl {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.fulltabs {
  @include row(20px);
}

.dropdown-toggle {
  background: transparent;
  border: 0;

  &:hover {
    background: transparent;
    border: 0;
  }
}

@media (max-width: 1024px) {
  .tab {
    width: 100%;
  }
}

.checkbox-wrapper {
  position: relative;
  width: 100%;
  right: 10px;
}

.checkbox-flex {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media (max-width: 720px) {
  .topdesti {
    margin-top: 30px;
    gap: 10px;
  }
}
