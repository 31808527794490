.circuit-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .inputcontainer {



        input {
            background: rgba(255, 255, 255, 0.6);
            backdrop-filter: blur(8px);
            width: 100%;
            height: 99px;

            position: relative;


            &::placeholder {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: clamp(15.5px, 1vw, 20px);
                line-height: 140%;
                color: #262626;
                opacity: 0.24;
            }
        }

        img {
            position: relative;
            right: 60px;
            margin-right: -50px;
        }


    }


    .row {

        background: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(8px);
        border-radius: 35px;
        padding: 15px;
        margin-right: 10px;

        .warning {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;


        }

        .border {
            border: none !important;
            border-right: 1px solid black !important;
        }

        .info {
            display: flex;
            flex-direction: column;
            position: relative;
            padding-top: 10px
        }

        h3 {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: clamp(8px, 0.7vw, 12px);

            height: 3%;
        }

        h4 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: clamp(15.5px, 1.3vw, 25px);
            color: #000000;
        }
    }


}

.hours-collapse {
    margin-bottom: -55px !important;

    .hours {
        font-family: Poppins;
        font-weight: bold;
        font-size: clamp(10px, 1vw, 15px);
        position: relative;
        top: 2px;


    }

    .days {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: clamp(10px, 1vw, 20px);
        color: #000000;

    }
}



.contact-collapse {
    .col-md-12 {
        background-color: rgba(241, 163, 0, 0.05);
        height: 40px !important;
        display: flex;
        align-items: center;
        padding-left: 0px;
        flex: 0 0 95.6% !important;
        margin-left: 15px;

        &:not(:first-child) {
            margin-top: 12px;
        }
    }

    .col {
        background-color: rgba(241, 163, 0, 0.05);

    }

    .icon {
        width: clamp(30px, 4vw, 45px) !important;
    }


}