.compass {
    background-color: black;
    height: 100vh;
    width: 100vw;
    display: flex;
  justify-content: center;
    .arrow{
        margin: 0 auto;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        transition: .2s ease-in-out;
    }
    .title{
        color: white;
        position: absolute;
        top: 70%;
        margin: 0 auto;
        font-family: inherit;
    }
    .distance{
        color: white;
        position: absolute;
        top: 70%;
        margin: 0 auto;
        font-family: inherit;
    }
}