#regionmap {
    background: rgba(0, 0, 0, 0.02) !important;
    // border-radius: 0px 30px 30px 0px !important;
    overflow: hidden !important;
    position: relative;
    width: 48vw;
    height: 100vh;

    .region-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 45vw;
        background-color: transparent;
        position: relative;
        z-index: 50;
        top: 0;

        .mapdistance {
            p {
                padding-left: 3px !important;
            }
        }

        .withborders {
            .mapdistance__img {
                display: flex;
                flex-direction: row;
                gap: 5px;
            }
        }

        .mapdistance__img {
            display: flex;
            flex-direction: row;
            gap: 5px;
        }
    }

    .poi-images {
        position: absolute;
        top: 80%;
        left: 10.41vw;

        &__img {
            width: 8.33vw;
            height: 8.14vw;
            border-radius: 20px;
        }
    }
}



@media (max-width: 768px) {
    #regionmap {
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        width: 100%;

        .mapdistancewrapper {
            display: flex;
            flex-direction: row;
            position: relative;
            justify-content: center;
            align-items: center;
            left: 18px;
            bottom: 50px;

            .withborders {
                border-right: 1px solid black;
                border-left: 1px solid black;
                width: 130px;
                padding-left: 17px;

                .mapdistance__img {
                    h5 {
                        font-size: 13px;
                    }
                }

                p {
                    font-size: 9px;
                }
            }

            .padding {
                padding-right: 12px;

                .mapdistance__img {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    width: 90px;
                    gap: 5px;
                    margin: 0;

                    img {
                        width: 100%;
                    }

                    h5 {
                        font-size: 13px
                    }
                }

                p {
                    font-size: 9px;
                }

            }

            .paddingLeft {
                padding-left: 10px;

                p {
                    font-size: 8px;
                }

                .mapdistance__img {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;

                    img {
                        width: 14px;
                    }

                    h5 {
                        font-size: 13px;

                    }
                }
            }
        }
    }
}



@media (max-width: 786px) {
    #regionmap {
        border-radius: 0 !important;
        border: 0 !important;
        background-color: transparent !important;

        .mapdistance {
            height: 50px !important;

            p {
                width: 65px;
            }

            &:last-child {
                p {
                    width: 48px;
                }

            }
        }

        p {
            padding-left: 4px;

        }

        .padding {
            padding-right: 8px !important;
        }



        .mapdistancewrapper {
            position: relative !important;

            left: 50% !important;
            transform: translateX(-25%) !important;
        }
    }
}