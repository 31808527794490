.newsletter {
    text-align: center;

    h1 {
        font-family: Poppins;
        font-weight: 900 !important;
        font-size: clamp(33px, 1.9vw, 40px) !important;
        color: black;
        width: 350px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    img {
        width: 50%;
        max-width: 100px;
    }

    p {
        font-family: Poppins;
        font-size: 18px;
        margin-top: 20px !important;
        width: 450px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    input {
        background: rgba(0, 0, 0, 0.03);
        border-radius: 12px;
        width: 600px;
        height: 60px;
        margin-top: 20px;
    }

    .u-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .newsletter {
        p {
            width: 90%;
        }

        input {
            width: 95%;
        }
    }
}