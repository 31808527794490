#circuit-card {
    cursor: pointer;
    transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    position: relative;
    border-radius: 12px;
    width: 100%;
    height: 100% !important;
    padding: 0px;
    z-index: 1;


    .shadow {
        width: 190px !important;

    }

    .price {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: clamp(10px, 1vw, 12px);
        line-height: 150%;

        padding-left: 12px;
        color: #FFDF01;
    }

    &:hover {
        cursor: pointer;
        transform: scale(0.98);
    }

    .container {
        width: 100%;
        height: 100%;
        position: relative;


    }

    .ttlContainer {
        position: absolute;
        bottom: 20px;
        left: 15px;
        width: 80%;
        z-index: -1;
    }

    .title {
        font-size: 12px;
        line-height: 17px;
        padding-left: 2px;
        padding-right: 2px;
        font-weight: 300;
        text-transform: capitalize;
    }

    .ttlContainertn {
        position: absolute;
        bottom: 40px;
        left: 25px;
        width: 80%;
        z-index: -1;
    }

    .titletn {
        
        line-height: 17px;
        max-width: 70%;
        font-weight: 300;
        color: white;
    }

    .image {
        width: 190px;
        height: 250px;
        object-fit: cover;
        border-radius: 20px;
        margin-bottom: 15px
    }
}


@media (max-width: 768px) {
    .other {
        .swiper-slide {
            width: fit-content !important;
        }
    }

    .offer {
        right: 250px !important;
        width: 90px !important;
    }

    #circuit-card {
        .shadow {
            margin-bottom: 0 !important;
            width: 190px !important;
        }

        .ttlContainer {
            position: absolute;
            bottom: 40px;
            width: 100%;

            .bold {
                font-weight: bold;
            }
        }

        .image {
            width: 190px;
            height: 280px !important;
        }
    }
}