.activepolygon {
    fill: #EC4949;
}

.activetext {
    fill: white
}

/*.Map {
   width: 48vw !important; //Why?

}*/

// @media (min-width: 1280px) and (max-width: 1400px) {
//     .Map {

//         margin-top: 45px;

//     }
// }

// @media (min-width: 1440px) and (max-width: 1900px) {
//     .Map {
//         margin-top: 22px;
//     }
// }



.mapdistancewrapper {
    margin-top: 75px;

    .mapdistance {
        margin-left: 56px;
        margin-bottom: 20px;

        &__img {
            align-items: center !important;
        }

        img {
            width: 1.42vw;
            height: 1.42vw;

        }

        h5 {
            font-family: Poppins;
            font-weight: bold;
            font-size: clamp(14px, 1.3vw, 18px);
            margin: 0;
        }

        p {
            font-family: Poppins;
            font-weight: 500;
            font-size: clamp(12px, 0.8174vw, 18px);
        }
    }
}


.Map {
    width: 100%;
    margin-top: 0 !important;

    svg {
        height: 75vh !important;
        width: 48vw;
    }

    //padding-bottom: 250px;

}



.trip-swip-nav {
    width: 8vw !important;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    p {
        margin: 5px !important;
        font-size: clamp(12px, 0.9vw, 16px) !important;
    }

}


.trip-wiper {
    width: 6.71875vw;
}

.cover-image {
    object-fit: cover;
}


@media (max-width: 768px) {
    .mapdistancewrapper .mapdistance img {
        width: 14px;
        height: 14px;
    }

    .mapdistance {
        margin: 0 !important;
    }

    .Map {
        svg {
            margin: 0 !important;
            margin-top: -30px !important;
            width: 100%;
            height: 250px !important;

        }


    }

    #regionmap {
        height: 430px !important;
    }
}