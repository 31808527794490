.whoarewe {
    p {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: clamp(16px, 1vw, 20px);
        line-height: 140%;
        width: 96%;
        color: #000000;
    }

    .download-container {
        display: flex;
        cursor: pointer;
        flex-direction: row;
        color: #000000;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 26px;
        position: relative;

        h6 {
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: clamp(16px, 1vw, 20px);
            position: relative;
            top: 5px;
        }
    }

    h4 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: clamp(16px, 1vw, 20px);
        line-height: 140%;

        color: #386CB9;
    }
}