.customize-journey {
    &__flex {
        display: flex;
        justify-content: space-between;
        width: 96%;
    }

    &__bar {
        transform: translateX(-110%);

    }

    &__icon {
        width: 50px;
        height: 50px;
        background: #386CB9;
        border-radius: 4px;
        position: relative;

        &--line {
            &-1 {
                background: white;
                position: absolute;
                width: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-51%, -50%);
                height: 3px;
                transition: all .4s;
            }

            &-2 {
                background: white;
                position: absolute;
                width: 50%;
                top: 51%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(90deg);
                height: 3px;
                transition: all .4s;
            }
        }

        input {
            position: absolute;
            -webkit-appearance: none;
            width: 100%;
            height: 100%;
            z-index: 999999;
            cursor: pointer;
        }



        input:checked~.customize-journey__icon--line-1 {
            transform: translate(-51%, -50%) rotate(45deg);
        }

        input:checked~.customize-journey__icon--line-2 {
            transform: translate(-51%, -50%) rotate(-45deg);
        }


    }
}