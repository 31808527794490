.react-calendar {
    width: 60%;
    margin-left: 18%;
    border: 0;

    .react-calendar__navigation__label__labelText {
        font-family: Poppins;
        font-weight: bold;
        font-size: 21.5951px;
        width: 10px !important;
    }

    .react-calendar__tile--active {
        background-color: #386CB9;
        width: 1% !important;
        color: white;
    }

    .react-calendar__month-view__weekdays__weekday {
        abbr {

            font-family: Poppins;
            font-weight: bold;
            font-size: 18.1167px;
            color: black !important;
            text-decoration: none;
        }

    }
}