.trip-card {
    position: relative;
    width: 100%;
    min-height: 200px;

    .distancetrip {
        position: absolute;
        background: rgba(255, 255, 255, 0.8);
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 9px !important;
        line-height: 150%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        z-index: 99;
        padding: 11px;
        object-fit: cover;
        left: 56px !important;
        top: 14px;
        color: #386CB9 !important;
        border-radius: 16.6061px;
    }

    .shadow-bg {
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 117px;
        z-index: 8;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }



    .img {
        width: 100%;
        height: 20vw !important;
        min-width: 150px;
        min-height: 150px;
        border: 1px solid white;
        object-fit: cover;

    }

    .img2 {
        width: 95%;
        height: 14.58vw !important;
        border: 1px solid white;
        object-fit: cover;
    }

    .img3 {
        width: 250px;
        height: 350px !important;
        border-radius: 30px !important;
        border: 1px solid white;
        object-fit: cover;

    }

    .titlewrapper {
        position: absolute;
        bottom: -8px;
        z-index: 999999;
        padding: 20px;
        width: 180px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;

        p {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            margin: 0px !important;
            color: #FFFFFF;
        }

        .title {
            color: #fff;
            font-size: 15px !important;

            font-weight: normal !important;
        }

    }


}



.searchcard {
    height: 300px;
    background: #FFFFFF;
    box-shadow: 0px -1px 12px 0px rgba(0,0,0,.1) !important;
    border-radius: 12px;
    border: none;
    font-family: inherit;
    padding: 5px;
    width: 90%;

    .Top-experiences_slider_image {
        height: 150px !important;
        border-radius: 15px !important;
        object-fit: cover;
    }

    .card-title {
        margin-top: 15px;
      
    padding-left: 10px;
        font-size: clamp(13px, 1.9vw, 16px) !important;
        font-weight: bold;
    }

    .card-text {
        
    padding-left: 10px;
        font-size: clamp(12px, 1.9vw, 12px) !important;
        opacity: 0.5;
        display: flex;
    }
}