@mixin row($gap) {
  display: flex;
  flex-direction: row;
  gap: $gap;
}

@mixin column($gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin grid($margin-top, $margin-bottom, $margin-left) {
  display: grid;

  grid-template: 15.625vw 15.625vw / 6vw 12vw 4vw 12vw;
  grid-column-gap: 2.86vw;
  grid-row-gap: 0.7vw;

  margin-bottom: $margin-bottom;
  margin-left: $margin-left;
  margin-top: $margin-top;
}