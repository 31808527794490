.swipTrip {
    width: 100% !important;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 80px;
    height: 100%;

    .ps {
        display: flex;
        flex-direction: column;
        gap: clamp(3px, 0.2vw, 16px);

        p {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: clamp(10px, 0.9vw, 16px) !important;
            /* identical to box height, or 18px */

            display: flex;
            align-items: center;

            color: #000000;
        }

    }

    .swiper-container {
        width: 43vw !important;

        .swiper-wrapper {
            height: 100% !important
        }

        .swiper-slide {
            width: 43% !important;
            height: 60% !important;
        }
    }

    @media (min-width: 1000px) and (max-width:1250px) {
        .swiper-container {
            width: 46vw !important;

            .swiper-slide {
                background-color: #000000;
                width: 48% !important
            }
        }
    }

    @media (min-width:1440px) and (max-width:1900px) {
        .swiper-container {
            width: 43vw !important;

            .swiper-slide {
                width: 45% !important
            }
        }
    }

    @media (min-width:1200px) and (max-width:1400px) {
        .swiper-container {
            width: 43vw !important;

            .swiper-slide {
                width: 46% !important
            }
        }
    }

    .card-body {
        height: 265px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        border-radius: 0px 20px;
        background: rgb(0, 0, 0);
        background: linear-gradient(45deg, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .4) 25%, transparent 40%);
    }

    .card {
        border-radius: 0px 20px;
    }


}

.topdeals {
    .card-body {
        padding: 5px 0 0 10px !important;
    }

}

.eventSwip {
    #circuit-card {
        .image {
            width: 220px !important;
            height: 220px !important;
        }

        >div {
            width: 220px !important;
        }

        height: 220px !important;
    }



    .eventinfo {
        margin-bottom: 16px;

        width: clamp(500px, 46.5vw, 1150px) !important;

        .readmore {
            font-weight: 800;
            text-decoration: none;
            color: black;
        }

        .readmore:hover {
            font-weight: 900;

        }


        h5 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: clamp(12px, 0.625vw, 18px);
            margin-bottom: 21px;
            color: rgba(0, 0, 0, 0.24);
        }

        h3 {
            font-family: Poppins;
            font-weight: 900;
            font-size: clamp(14px, 1.25vw, 20px);
        }

        p {
            font-family: Poppins;
            font-weight: normal;
            font-size: clamp(12px, 0.8vw, 18px);
        }

        img {
            width: 21vw;
            height: 19vw;
            object-fit: cover;
            border-radius: 20px;


        }

        button {
            font-family: Poppins;
            font-size: clamp(12px, 1.041vw, 18px);
            background-color: transparent;
            border: 0;
            font-style: normal;
            position: relative;
            font-weight: 500;
            right: 10.6px;
            color: #386CB9;
            text-decoration: underline;
        }

        @media (max-width:768px) {
            display: none !important;
        }
    }

    .card {
        height: 10vw !important;
        min-height: 250px;
        width: 100% !important;


        .card-body {
            height: 100% !important;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;


            background: rgb(0, 0, 0);
            background: linear-gradient(45deg, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .4) 15%, transparent 40%);

        }
    }


}

.searchSwiper {
    position: relative;
    top: 120px;
    width: 50vw;

    .card {
        background: #fff;
        border-radius: 10px;
        cursor: grab;
        height: 300px;
        position: relative;

        img {
            border-radius: 7px;
            margin-left: 5%;
            margin-top: 5%;
            width: 90%;
        }

        .card-title {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 900;
            letter-spacing: 0.44px;
            line-height: 24px;
            margin: 0;
            padding: 0;
            width: 500px;
        }

        .card-text {
            color: #383874;
            font-family: Poppins;
            font-size: 13px;
            font-weight: 300;
            letter-spacing: 0.44px;
            line-height: 17px;
        }
    }
}

.fav {
    width: 32px !important;
    height: 32px !important;
    margin-left: 150px;
    margin-top: 10px;
}

.swiper2 {
    margin-top: 80px;
}

.swiper1 {
    margin-top: 50px;
}

.swipericon {
    left: 290px;
    position: absolute;
    top: 12px;
    width: 35px !important;
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .swipericon {
        left: 140px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .swipericon {
        top: 15px;
        left: 180px;
    }
}

@media (min-width: 1440px) and (max-width: 1900px) {
    .swipericon {
        top: 18px;
        left: 215px !important;
    }
}

@media (max-width: 1440px) {
    .trip-swip-nav {
        gap: 3vw !important;
    }
}


.images-region-map {
    position: absolute;
    top: 40%;
    transform: translateY(-50%) !important;

    left: 56px;

    img {
        width: 8.33vw;
        height: 8.14vw;
        border-radius: 20px;
    }
}


@media (max-width: 768px) {
    .images-region-map {
        display: none;
    }

}


.topdeals {
    .swiper-container {
        height: 20vw !important;
        min-height: 200px
    }
}


.similar-slider {


    .trip-card {
        width: 190px !important;
        min-width: 250px;

        .img3 {
            width: 13vw !important;
            height: 20vw !important;
            min-width: 250px;
            min-height: 250px;

        }
    }

    .swiper-container {
        width: 100% !important;

        .swiper-wrapper {
            height: 100% !important
        }

        .swiper-slide {
            width: 30% !important;
            height: 60% !important;
        }
    }

    @media (min-width: 1000px) and (max-width:1250px) {
        .trip-card {
            width: 16vw
        }

        .swiper-container {
            width: 43vw !important;

            .swiper-slide {
                width: 35% !important
            }
        }
    }

    @media (min-width:1440px) and (max-width:1900px) {
        .swiper-container {
            width: 47vw !important;

            .swiper-slide {
                width: 29% !important
            }
        }
    }

    @media (min-width:1200px) and (max-width:1400px) {
        .swiper-container {
            width: 46vw !important;

            .swiper-slide {
                width: 30% !important
            }
        }
    }
}

.city-swiper {
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    height: 48vh;

    .bottom-city {
        display: flex;
        flex-direction: row;
        justify-content: space-between !important;
        align-items: center;
        position: relative;
        z-index: 999999 !important;
    }


    .swiper-container {
        width: 48vw !important;

        .swiper-wrapper {
            height: 100% !important
        }

        .swiper-slide {
            width: 33% !important;
            height: 60% !important;
        }
    }

    .button-city {
        font-size: clamp(10px, 0.7vw, 22px) !important;
    }

    @media (min-width: 1000px) and (max-width:1250px) {

        height: 35vh;

        .bottom-city {

            .arrows-city {

                margin-top: 16px !important;
            }
        }

        .trip-card {
            width: 16vw
        }

        .swiper-container {
            width: 48vw !important;

            .swiper-slide {
                width: 35% !important
            }
        }
    }

    @media (min-width:1440px) and (max-width:1900px) {


        .bottom-city {




            .arrows-city {

                position: relative !important;
                left: 10px !important;
                margin-top: 16px !important;
            }
        }

        .swiper-container {
            width: 48vw !important;

            .swiper-slide {
                width: 35% !important
            }
        }
    }

    @media (min-width:1200px) and (max-width:1400px) {


        .bottom-city {

            .arrows-city {

                position: relative !important;
                left: 10px !important;
                margin-top: 16px !important;
            }
        }

        .swiper-container {
            width: 50vw !important;

            .swiper-slide {
                width: 35% !important
            }
        }


        height: 42vh !important;

    }
}

@media (max-width: 767px) {
    .eventSwip {

        .swiper-slide {
            width: 210px !important;
        }


        #circuit-card {
            .shadow {
                margin-bottom: -60px !important;
                width: 190px !important;
            }

            .ttlContainer {
                position: absolute;
                bottom: 0px;
                left: 10px;
                width: 70% !important;
            }

            .image {
                width: 190px !important;
                height: 280px !important;
            }
        }
    }


}