.modal-open {
    overflow: auto !important;
}

.nextpage {
    font-family: Poppins;
    font-weight: 900;
    z-index: 99;
    width: 100%;
    cursor: pointer;
    margin-top: 30px;
    position: relative;
    left: -19px;
    font-size: 25px;
    cursor: pointer;

    span {
        color: #FCC100;
    }
}

#submodal {
    width: 60vw !important;
    min-width: 800px;

    height: 578px;
    background: #FCC100;
    border-radius: 40px;
    margin: 20vh 0 0 30vw;
    overflow: hidden;

    .modal-content {
        padding: 42px;
        width: 100%;
        height: 100%;
        background: #FCC100;
        color: black;
    }


    h1 {
        font-family: Poppins;
        font-weight: 900;
        z-index: 99;
        width: 100%;
    }

    .confindential {
        font-family: Poppins;
        font-weight: 500;
        font-size: clamp(14px, 0.9375vw, 18px);
        width: 100%;
        margin-bottom: 36px !important;
        margin-top: 20px !important;

    }

    .enter-email {
        font-family: Poppins;
        font-size: clamp(12px, 0.83vw, 19px);
        color: #262626;

        z-index: 99;

        width: 100%;
        margin-bottom: 10px !important;

    }

    .input-divs {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 20px;
        margin-top: 16px;

        >div {
            width: 100%;
        }
    }

    .input-div {

        z-index: 99;

        width: 100%;


    }

    input {
        width: 100%;
        height: 3.125vw;
        border-radius: 12px;
        background-color: white;
        border-color: transparent;
    }

    .button-div {

        z-index: 99;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 36px;

        button {
            background-color: #386CB9;
            border: none;
            border-radius: 12px;
            height: 3.125vw;
            width: 7.29vw;
            font-family: Poppins;
            color: white;
            font-weight: 900;
            font-size: clamp(14px, 0.83vw, 19px);
        }
    }


}

@media (max-width: 768px) {
    #submodal {
        width: 300px;
        height: 578px;
        background: #386CB9;
        border-radius: 40px;
        margin: 10% 0 0 10%;
    }

    #submodal h1 {
        font-size: 15px;
    }

    #submodal .confindential {
        font-size: 10px !important;
    }

    #submodal .enter-email {
        font-size: 10px !important;
    }

    #submodal input {
        width: 250px !important;
        height: 40px !important;
    }

    #submodal button {
        height: 22px !important;
        width: 60px !important;
        font-size: 9px !important;
    }

    #submodal .confidential-link {
        font-size: 10px;

    }
}