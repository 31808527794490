.Home-search-logo-holder{
    border-radius: 30px;
    height: 200px;
    left: 50%; 
    position: absolute; 
    top: 25%; 
    transform: translateX(-50%);
    width: auto; 
    z-index: 10;
}



