#button-component{
    button {
        background: #386CB9;
        color: white;
        border-radius: 12px;
        width: 140px;
        height: 60px;
        border: 0;
        position: relative;
        left: 5px;
        font-family: Poppins;
        font-weight: 900;
        font-size: clamp(14px, 0.83vw, 18px);
    
    }
}
