.modal-container {
  width: 61.3125rem;
  height: 35.625rem;
  background-color: #ffffff;
  padding: 0;

  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .closeButton {
    width: fit-content;
    height: fit-content;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 999;
  }
  .screens {
    width: 27.5rem;
    height: 100%;
    background: #85cee3;
    position: relative;
    overflow: hidden;

    .phone1 {
      width: 10.1875rem;
      height: 14.25rem;
      flex-shrink: 0;
      position: absolute;
      top: -4.5rem;
      left: 0.5rem;
    }
  }
  .phone2 {
    width: 27.75rem;
    height: 35.5625rem;
    position: absolute;
    top: 9.94rem;
    left: 4.31rem;
    z-index: 999;
  }

  .content {
    width: 100%;
    height: 100%;
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    padding-left: 6.5rem;
    padding-right: 6.5rem;

    h1 {
      max-width: 14.375rem;
      color: #000;
      font-family: Poppins;
      font-size: 3rem;
      font-style: normal;
      font-weight: 600;
      line-height: 3.0625rem; /* 102.083% */
      letter-spacing: -0.15rem;
      text-transform: capitalize;
    }

    p {
      max-width: 20.4375rem;
      color: #212529;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
      opacity: 0.7;
    }

    .actions {
      height: 2.87394rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px;
      gap: 9px;
      a {
        cursor: pointer;
        img {
          width: 9.31113rem;
          height: 2.81213rem;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 61.3125rem !important;
  }
}

@media (max-width: 720px) {
  .modal {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .modal-container {
    width: 100%;
    height: 42.4375rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;

    .closeButton {
      z-index: 99999;
      background-color: #ffffff;
      svg {
        :first-child {
          fill: #ffffff;
        }
      }
    }
    .screens {
      width: 100%;
      height: 19.375rem;
      background: #85cee3;

      .phone1 {
        width: 10.1875rem;
        height: 14.25rem;
        position: absolute;
        top: -4.5rem;
        left: 0.5rem;
      }
    }

    .phone2 {
      width: 15.5rem;
      height: 19.9375rem;
      flex-shrink: 0;
      position: absolute;
      top: 3rem;
      left: 11rem;
    }
    .content {
      width: 100%;
      height: 100%;
      flex: 1;
      padding: 0;
      padding-top: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      h1 {
        max-width: 100%;
        font-size: 2rem;
        line-height: 2.0625rem;
        letter-spacing: -0.1rem;
      }
      p {
        max-width: 100%;
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
      .actions {
        height: 2.87394rem;
        padding: 0;
        gap: 7px;
        flex-wrap: wrap;
        a {
          cursor: pointer;
          img {
            width: 9.31113rem;
            height: 2.81213rem;
          }
        }
      }
    }
  }
}
