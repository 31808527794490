.leftsidedeals {
    background-color: #FCC100;
    color: black;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    height: 100vh;
    width: 48vw;
    padding-top: 12vh;
    padding-left: 40px;

    h3 {
        font-family: Poppins;
        font-weight: 900;
        font-size: clamp(18px, 1.45vw, 20px);
        margin-bottom: 8px;
    }

    p {
        font-family: Poppins;
        font-weight: 500;
        font-size: clamp(14px, 0.9375vw, 18px);
    }

    label {
        margin-top: 36px;
        font-family: Poppins;
        font-size: clamp(12px, 0.83vw, 18px);
    }

    select {
        background: #FFFFFF;
        border-radius: 12px;
        height: 60px;
        padding-left: 24px;
        -moz-appearance: none;
        /* Firefox */
        border: none;
        -webkit-appearance: none;
        /* Safari and Chrome */
        appearance: none;
        background-image: url("../../images/selectarrow.svg");
        background-repeat: no-repeat;
        background-position-y: 19px;
    }

    input {
        background: #FFFFFF;
        border-radius: 12px;
        height: 60px;
        width: 45.5%;
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        /* Safari and Chrome */
        appearance: none;
        background-image: url("../../images/selectarrows.svg");
        background-repeat: no-repeat;
        background-position-x: 95.5%;
        background-position-y: 13px;
    }

    input[type=number] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
    }
}

.strikethrough {
    position: relative;
    color: #26262657;
}

.strikethrough:before {
    position: absolute;
    content: "";
    left: 5px;
    top: 40%;
    right: 0;
    width: 40px;
    color: black !important;
    border-top: 4px solid;
    border-color: inherit;

    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-45deg);
}

@media (max-width: 768px) {
    .leftsidedeals input {

        background-position-x: 98%;
        background-position-y: 2px;
    }

    .leftsidedeals select {

        background-position-x: 98%;
        background-position-y: 2px;
    }

    .strikethrough:before {
        position: absolute;
        content: "";
        left: 0px;
        top: 40%;
        right: 0;
        width: 20px;
        color: black !important;
        border-top: 1px solid;
        border-color: inherit;

        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-45deg);
    }
}