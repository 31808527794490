.left-side-container {
    margin-top: 24vh;

    .lsc-actions {
        margin-top: 50px;
    }
}





.slide1 {
    height: 100%;
    width: 100%;
}

.slide2 {
    height: 100%;
    width: 100%;
}

.swiper-wrapper {
    h1 {
        color: #fff;
        font-family: Sansita;
        letter-spacing: 0.01em;
        line-height: 89.5%;
    }

    h2 {
        color: #fff;
        font-family: Poppins;
        font-size: clamp(14px, 1.5vw, 18px);
        font-weight: map-get($font-weights, "bold");
        line-height: 87%;
    }

    .titleLogo {
        bottom: 61.28%;
        left: 90px;
        right: 49.33%;
        top: 11vw;
        width: 30%;
    }
}

.swiper-button-next {
    position: absolute;
    top: 100px;
    right: 500px;
    width: 100px;
    height: 60px;
}

.sliderTitle {
    width: 100%;
}

@media (max-width: 1024px) {
    .container-fluid h1 {
        /*top: 30vw!important;*/
    }

    .container-fluid h2 {
        /*top: 32vw!important;*/
    }

    .container-fluid img {
        /* top: 25vw!important;*/
    }
}