.searchform {
  @include row(0);
  left: 0px;
  margin-top: 90px;
  position: relative;
  width: 97%;
  box-shadow: 0px 0px 16px 20px rgba(0, 0, 0, 0.02);
  border-radius: 50px;

  .searchbtn {
    background-color: #1381fe !important;
    border-color: transparent;
    border-radius: 50px;
    color: #ffffff !important;
    font-family: Poppins !important;
    font-size: 16px;
    font-weight: map-get($font-weights, "medium");
    height: 60px;
    width: 150px;
  }

  .container {
    position: relative;
  }

  .main {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 50px;
    box-sizing: border-box;
    color: #555;
    font-size: 13px;
    height: 60px;
    padding: 4px 11px 4px 24px;
    position: relative;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  ::placeholder {
    font-family: Poppins;

    font-size: clamp(14px, 1vw, 20px);
    font-weight: normal;
  }

  .searchicon {
    background: transparent;
    border: 3px solid #909090;
    border-radius: 100%;
    height: 22px;
    right: 40px;
    position: absolute;
    top: 18px;
    width: 22px;

    &:after {
      background: #909090;
      bottom: -5px;
      content: "";
      height: 10px;
      position: absolute;
      right: -4px;
      transform: rotate(-45deg);
      width: 3px;
    }
  }

  .microphone {
    background: #ffffff;
    border-radius: 100%;
    height: 30px;
    position: absolute;
    right: 35px;
    top: 15px;
    width: 30px;

    &:after {
      border: 1px solid #555;
      border-radius: 10px;
      content: "";
      height: 12px;
      left: 11px;
      position: absolute;
      top: 5px;
      width: 8px;
    }

    &:before {
      border-bottom: 2px solid #555;
      border-radius: 100%;
      content: "";
      height: 10px;
      left: 10px;
      position: absolute;
      top: 8px;
      width: 10px;
    }

    .mic-stand {
      border: 1px solid #555;
      bottom: 7px;
      height: 4px;
      left: 14px;
      position: absolute;
      width: 2px;
    }
  }
}

@media (max-width: 1024px) {
  .searchform {
    margin-left: 20px;
    width: 100%;
  }

  .main {
    width: 100% !important;
  }

  ::placeholder {
    font-size: 20px !important;
  }
}