.TicketsSwiper {
    .swiper-slide {
        &:not(:first-child) {
            margin-left: 10px;
        }
    }
}

.ticketcard {


    height: 100%;
    width: 100%;
    color: white;
    text-align: center;
    border-radius: 12px;
    overflow: hidden;

    &__info {
        border-bottom: 3px dashed white;
        position: relative;

        &::before {
            content: "";
            width: 30px;
            height: 30px;
            background: white;
            border-radius: 1000px;
            position: absolute;
            z-index: 99999;
            right: -20px;
            bottom: -16px;
        }

        &::after {
            content: "";
            width: 30px;
            height: 30px;
            background: white;
            border-radius: 1000px;
            position: absolute;
            z-index: 99999;
            left: -20px;
            bottom: -16px;
        }
    }


    >* {
        background: #262626;
        padding: 21px 32px;
    }

    h4 {

        font-weight: 500;
        font-size: 18px;
        margin: 0;
        position: relative;
        border-bottom: 3px dashed white;

        &::before {
            content: "";
            width: 30px;
            height: 30px;
            background: white;
            border-radius: 1000px;
            position: absolute;
            z-index: 99999;
            right: -20px;
            bottom: -16px;
        }

        &::after {
            content: "";
            width: 30px;
            height: 30px;
            background: white;
            border-radius: 1000px;
            position: absolute;
            z-index: 99999;
            left: -20px;
            bottom: -16px;
        }
    }

    p {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 140%;

        span {
            font-weight: bold;
        }
    }

    &__btns {
        svg {
            display: none;
        }

        position: relative;

        .chq-atc--dropdown {
            display: flex;
            flex-direction: column;

            a {
                color: #FCC100;
                border: none;
                background: transparent;
                font-size: 15px;

            }

        }
    }

    button {
        color: #FCC100;
        border: none;
        background: transparent;
    }
}