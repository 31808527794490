#stade-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .col-md-3 {
        width: 100%;
        margin-right: 25px;

        p {
            font-size: clamp(12px, 1vw, 18px)
        }
    }

    .radiobox {
        display: flex;
        flex-direction: row;
        gap: 10px;

        a {
            text-decoration: none;
            color: black
        }

        .or-wrapper {
            font-size: clamp(12px, 1vw, 18px);
            margin-top: 150px
        }

        &__box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            gap: 18px;
            margin-top: 10px;
            width: 15.4vw;
            height: 20vw;

            &--planningimg {
                width: 19vw;
                height: 19vw;
                position: relative;
                margin-top: -90px;
                z-index: 99;
            }

            &--visitingimg {
                width: 15vw;
                position: relative;
                height: 18vw;
                margin-top: -70px;
                z-index: 99;
            }

            p {
                font-family: Poppins;
                font-style: normal;
                font-weight: 900;
                font-size: clamp(16px, 1.2vw, 25px);
                line-height: 110%;
                color: #000000;
                text-transform: lowercase;
            }

            
            p::first-letter {
                text-transform: uppercase !important;
            }
  
        }
    }
}

@media (max-width: 768px) {
    #stade-row {
        background: #F2F2F2;
        border-radius: 20px;
        width: 100%;
        height: 400px;
        margin-left: 0.3%;
        padding: 24px 0 0 6px;

        .col-md-3 {
            h1 {
                font-size: 18px;
                width: 100%
            }

            p {
                font-size: 8px;
                width: 100%
            }
        }

        .radiobox {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            gap: 10px;
            margin-top: 11px;

            .or-wrapper {
                font-size: 10px;
                margin-top: 120px
            }

            &__box {
                width: 128.31px;
                height: 160.39px;
                background: #FFF !important;
                margin-bottom: 25px;
                margin-top: 50px;

                &--planningimg {
                    width: 64.16px;
                    height: 153.26px;
                    position: relative;
                    z-index: 99;

                }

                &--visitingimg {
                    width: 84.83px;
                    position: relative;
                    height: 138.29px;
                    z-index: 99;


                }
            }
        }
    }
}