.contactpage {
  input.checkbox:checked {
    opacity: 1 !important;
    position: relative !important;
  }
  a {
    text-decoration: none !important;
  }

  h1 {
    font-family: Poppins;
    font-weight: 900;
    font-size: clamp(18px, 1.71vw, 22px);
    margin-top: 12px;
  }

  .contactdiv {
    margin-top: 28px;
    margin-bottom: 36px;

    .row {
      display: flex;
      flex-direction: column;
      gap: 20px;

      span {
        font-size: clamp(16px, 1vw, 22px);
      }
    }

    .col-md-12 {
      background-color: rgba(241, 163, 0, 0.05);
    }
  }

  h4 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 12px;
  }

  button {
    background: #2273ba;
    border-radius: 12px;
    width: 100%;
    height: 60px;
    font-family: Poppins;
    font-weight: 900;
    font-size: 18px;
    color: white;
    margin-top: 36px;
  }

  input[type="text"],
  input[type="email"] {
    background: #f2f2f2;
    border-radius: 12px;
    width: 100%;
    height: 60px;
  }

  span {
    font-family: Poppins;
    font-weight: 500;
    font-size: clamp(14px, 0.9375vw, 18px);
    margin-left: 30px;
  }

  textarea {
    background: #f2f2f2;
    border-radius: 12px;
    width: 100%;
    height: 140px;
    border: none;
  }

  label {
    font-family: Poppins;
    font-size: 18px;
    margin-left: 10px;
  }

  .contact_link {
    cursor: pointer;
    color: black;
    font-family: Poppins;
    font-weight: 500;
    font-size: clamp(14px, 0.9375vw, 18px);
 
  }
}
