.visa {
  width: 100%;

  h1 {
    font-family: Poppins;
    font-weight: 900;
    font-size: clamp(12px, 0.8vw, 16px);
    padding-top: 60px;
  }

  p {
    font-family: Poppins;
    font-size: clamp(12px, 0.8vw, 16px);
    width: 90%;
    padding-top: 16px;
  }

  h5 {
    font-family: Poppins;
    font-weight: bold;
    font-size: clamp(15px, 0.8vw, 16px);
    padding-top: 24px;
    width: 90%;
  }

  h2 {
    font-family: Poppins;
    font-weight: bold;
    font-size: clamp(16px, 0.8vw, 16px);
    margin-left: 40px;
  }

  .bottomP {
    font-family: Poppins;
    font-size: clamp(12px, 0.8vw, 16px);
    width: 380px;
    margin-left: 40px !important;
  }

  .country-select {
    width: 600px !important;
    height: 60px;
  }

  .form-control {
    background: #ffffff !important;
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    box-sizing: border-box;
    border-radius: 12px;

    margin-top: 20px;
    padding-left: 40px;
  }
  button {
    font-family: Poppins;
    font-weight: 900;
    font-size: clamp(12px, 0.8vw, 18px);
    width: 237px;
    height: 60px;
    background: #2273ba;
    border-radius: 12px;
    color: white;
    margin-top: 55px;
  }
}

.around {
  h1 {
    font-family: Poppins;
    font-weight: 900;
    font-size: 33px;
    margin-top: 60px;
  }

  p {
    font-family: Poppins;
    font-size: clamp(12px, 0.8vw, 16px);
    width: 604px;
    margin-top: 30px !important;

    span {
      font-family: Poppins;
      font-weight: bold;
      font-size: clamp(14px, 0.8vw, 16px);
    }
  }

  button {
    font-family: Poppins;
    font-weight: 900;
    font-size: clamp(14px, 0.8vw, 16px);
    width: 237px;
    height: 60px;
    background: #2273ba;
    border-radius: 12px;
    color: white;
    margin-top: 55px;
  }
}

.urgency {
  h1 {
    font-family: Poppins;
    font-weight: 900;
    font-size: 33px;
    margin-top: 60px;
  }

  p {
    font-family: Poppins;
    font-size: clamp(12px, 0.8vw, 16px);
    margin-top: 10px !important;

    span {
      font-family: Poppins;
      font-weight: bold;
      font-size: clamp(14px, 0.8vw, 16px);
    }
  }
}

.currency {
  h1 {
    font-family: Poppins;
    font-weight: 900;
    font-size: 33px;
    margin-top: 60px;
  }

  p {
    font-family: Poppins;
    font-size: clamp(12px, 0.8vw, 16px);
    width: 604px;
    margin-top: 16px;
  }

  h5 {
    font-family: Poppins;
    font-weight: bold;
    font-size: clamp(16px, 0.8vw, 22px);
    margin-top: 24px;
    margin-bottom: 50px;
  }

  a {
    font-family: Poppins;
    text-decoration: none;
    padding: 20px;
    font-weight: 900;
    font-size: clamp(12px, 0.8vw, 16px);
    width: 237px;
    height: 60px;
    background: #2273ba;
    border-radius: 12px;
    color: white;
    margin-top: 10% !important;

    &:hover {
      color: white;
    }
  }
}

.climat {
  h1 {
    font-family: Poppins;
    font-weight: 900;
    font-size: 33px;
    margin-top: 60px;
  }

  p {
    font-family: Poppins;
    font-size: clamp(12px, 0.8vw, 16px);
    width: 604px;
    margin-top: 16px;
  }

  button {
    font-family: Poppins;
    font-weight: 900;
    font-size: clamp(12px, 0.8vw, 18px);
    width: 237px;
    height: 60px;
    background: #2273ba;
    border-radius: 12px;
    color: white;
    margin-top: 55px;
  }
}

.retour {
  
  padding-left: 16px;
  font-family: Poppins;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;

  background: transparent !important;
  border-radius: 0px !important;
  height: 50% !important;
  position: relative;
  cursor: pointer;

  color: #386cb9 !important;

  display: flex;
  justify-content: flex-start;

  span {
    color: #ffffff;
    background: #386cb9;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px !important;
    height: 26px;
    margin-right: 17px;
  }
}

@media (max-width: 768px) {
  .visa,
  .currency,
  .around {
    width: 100%;
    padding-left: 0px;
    margin-left: 0px;

    h1 {
      font-size: 18px;
      width: 100%;
      margin-top: 0px;
    }

    h2 {
      font-size: 12px;
      width: 100%;
    }

    p {
      width: 90%;
      font-size: 10px;
    }

    h5 {
      font-size: 12px;
    }

    .col-md-2,
    .col-md-4 {
      width: 100%;
    }
  }
}
