.checkbox-wrapper {
    width: 48vw;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.infopratique {
    display: inline-block;
    background: #FBFDFB;
    border: 1px solid rgba(241, 163, 0, 0.2);
    box-sizing: border-box;
    border-radius: 20px;
    width: 46% !important;



    img {
        padding-bottom: 15px;
    }


    height: 125px;
    padding: 25px;
    margin: 10px;

    h2 {
        font-family: Poppins;
        font-weight: 900;
        font-size: clamp(12px, 1.30vw, 18px);
    }

    p {
        font-family: Poppins;
        font-size: clamp(12px, 0.78125vw, 18px) !important;
        margin-top: 0 !important;
    }



}


.check-elements {

    input {
        opacity: 0
    }


}


.active-info {
    background: #FFEF80;
}

@media (max-width: 768px) {
    .checkbox-wrapper {
        >div {
            width: 50%;
            height: 55.56px;
        }
    }

    .infopratique {
        width: fit-content !important;
        height: 65px;
        padding-top: 10px !important;
        padding-bottom: 10px !important;

        img {
            padding-bottom: 15px;
            width: 36px;
        }

        h2 {


            font-size: 10px;
        }

        p {

            font-size: 4.5px !important;
        }

    }
}