.select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    height: 60px;
    padding-left: 20px;
    margin-top: 20px;
}