.trip-component {
    .swiper-grid-arrows {
        margin-top: 70px !important
    }

    transition: all 4s;

    .number-trip {
        background-color: #FCC100;
        width: 25px;
        display: flex;
        justify-content: center;
        border-radius: 60px;
    }

    .input-container {
        display: flex;
        justify-content: flex-start;
    }

    .row-1 {
        display: flex;
        justify-content: space-around;
        width: 100%;

    }

    .row-2 {
        display: flex;
        align-items: center
    }

    .share-text {
        font-family: Poppins;
        font-size: clamp(12px, 0.9375vw, 18px)
    }

    input {
        background: #F2F2F2;
        border-radius: 12px;
        width: 100%;
        height: 45px;
    }

    button {
        background-color: #386CB9;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 12px;
        width: 100%;
        height: 48px;
        color: white;
        font-family: Poppins;
        font-weight: 900;
        font-size: 16px;
        margin-bottom: 40px;
        text-transform: capitalize;
    }

    p {
        span {
            font-family: Poppins;
            font-weight: bold;
            font-size: 18px;
        }

        font-family: Poppins;
        font-weight: 500;
        font-size: 18px;
    }
}

.trip-bar {
    border: 3px solid black;
    width: 97% !important;
    margin-left: 0 !important;
    box-shadow: 0px 0px 40px 12px rgba(0, 0, 0, 0.02);
    border-radius: 40px;
    padding: 42px 20px;
    padding-left: 40px !important;

    &__btns {
        display: flex;
        flex-direction: row;
        width: fit-content;
        text-align: right;
        align-items: center;
        width: 95%;
        gap: 20px;
        justify-content: flex-end;

        >div {
            width: 150px;

            p {
                position: relative;
                top: 5px;
                font-size: 18px !important;
            }
        }

        button {
            width: 100% !important;

        }
    }

    .inputcontainer {
        width: 96%;
        position: relative;

        &__container {
            &:not(:first-child) {
                margin-top: 30px;
            }
        }


        &__city {
            margin-left: 17.5px;
            margin-bottom: 10px !important;

            &:not(:first-child) {
                margin-top: 5px;

            }
        }

        input {
            background: rgba(0, 0, 0, 0.03);
            -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
            border-radius: 36.5468px;
            width: 100%;
            height: 60px;

            &::placeholder {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: clamp(15.5px, 1vw, 20px);
                line-height: 140%;
                color: #262626;
                opacity: 0.24;
            }
        }

        img {
            position: absolute;
            top: 20px;
            right: 70px;
            margin-right: -50px;
        }
    }

    h5 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: clamp(15px, 1vw, 19px);
        line-height: 27px;
        color: #000000;
        padding-left: 8px;
        text-transform: capitalize;
        margin-bottom: 19px;
    }

    h4 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: clamp(12px, 1vw, 16px);
        line-height: 27px;
        color: #000000;
        padding-left: 8px;
        text-transform: capitalize;
        margin-bottom: 19px;
    }

    &__row {
        justify-content: flex-end;
        align-items: center;
        position: relative;
        width: 100%;


        &-col1 {
            display: flex;
            justify-content: flex-end;
            position: absolute;
            right: 50px;

            button {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: clamp(12px, 1vw, 18px);
                line-height: 110%;
                color: #000000;
                background: transparent;
                border: none;
            }
        }

        &-col2 {

            position: absolute;
            right: 49px;

            button {
                font-family: Poppins;
                font-style: normal;
                font-weight: 900;
                font-size: clamp(12px, 1vw, 18px);
                background: #2273BA;
                padding: 20px 32px;
                border-radius: 12px;
                line-height: 110%;
                color: #FFFFFF;
            }

        }
    }
}


.starting-point {
    position: absolute;
    top: 6%;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    z-index: 99;

    input {
        display: block;

        width: 100%;
        background: rgba(255, 255, 255, 0.6) !important;
        backdrop-filter: blur(8px);
        height: 60px;
        border-radius: 36.5468px;

        &::placeholder {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: #262626;
            opacity: 0.24;
        }
    }
}

.plan-swiper {
    position: relative;

    .card {
        width: 90% !important;
    }

    &__plus {
        position: absolute;
        right: 0px;
        bottom: -60px;
        z-index: 99;
        cursor: pointer;

        :active {
            width: 90%;
        }
    }

    &__minus {
        position: absolute;
        right: 45px;
        top: 15px;
        z-index: 99;
        cursor: pointer;

        :active {
            width: 90%;
        }
    }

    &__line {
        position: absolute;
        right: 0px;
        left: 0;
        margin-right: 10%;
        bottom: -42px;
        width: 80%;
        height: 1px;
        background-color: #729ED9;
        z-index: 99;
        cursor: pointer;
    }

    &__distance {
        position: absolute;
        right: calc(50% - 10px);
        bottom: -40px;
        z-index: 99;
        cursor: pointer;
        font-family: inherit;
        font-size: 12px;
    }





    .swiper-container {
        width: 100% !important;

        .swiper-wrapper {
            height: 100% !important
        }


    }

}

@media (max-width: 767px) {
    .trip-component {
        .row-1 {
            width: auto;
        }
    }

    .trip-bar {
        input {
            &::placeholder {
                font-size: 14px !important;
            }
        }
    }
}