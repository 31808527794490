@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap");
@import "~bootstrap/scss/bootstrap";
@import "bootstrap-daterangepicker/daterangepicker.css";

@import "variables";
@import "mixins";
@import "./layout/flexItems";
@import "./placesAutocomplete/placesAutocomplete";
@import "./compass/compass";
@import "./mobile/bottomnav";
@import "./mobile/welcome";
@import "./search/SearchAndFilters";
@import "./layout/nav";
@import "./layout/scrollbar";
@import "./explore/tabmenu";
@import "./explore/customizeform";
@import "./POI/poi";
@import "./POI/poinav";
@import "./leftside/prettytitle";
@import "./leftside/slideshow";
@import "./search/searchbar";
@import "./search/searchcomponents";
@import "./search/searchswipers";
@import "./grids/imagesgrid";
@import "./explore/infopratique";
@import "./explore/newsletter";
@import "./explore/calendar";
@import "./explore/mapregion";
@import "./deals/deals";
@import "./deals/dealsswiper";
@import "./Exp/experiences";
@import "./plan/trip-component";
@import "./leftside/leftsideinfo";
@import "./explore/subscribe-modal";
@import "./layout/navmenus";
@import "./contact/contactpage";
@import "./layout/footer";
@import "./plan/trippage-swiper";
@import "./cards/tripcards";
@import "./cards/circuitcard";
@import "./leftside/circuitinfo";
@import "./plan/select-visa";
@import "./contact/whoarewe";
@import "./mobile/regionmap";
@import "./mobile/stade";
@import "./plan/customizejourney";
@import "./layout//button-component.scss";
@import "./cards/tickets.scss";
@import "./cards/citycard.scss";
@import "./scrolldown.scss";

html,
body {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  -webkit-text-size-adjust: none !important;
  width: 100vw;
  max-width: 100vw;
  min-height: 100% !important;
  overflow-x: hidden;
}

iframe {
  border-width: 0;
}

input {
  padding-left: 25px !important;
}

.H1 {
  font-size: clamp(30px, 1.9vw, 40px) !important;
  font-weight: 900 !important;
  text-transform: none !important;
  color: black !important;
  text-transform: capitalize;
}

textarea:focus,
input:focus {
  outline: none;
}

input,
button {
  border: none !important;
  padding: 0 10px 0 10px;
}

.swiper-slide {
  margin-right: 0 !important;
}

.loadingimg {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .__video {
    width: 90%;
    max-width: 250px;
    height: auto;
    max-height: 90%;
  }
}

.modal-content {
  border: 0 !important;
}

.modal-open .container-fluid,
.modal-open .container {
  filter: blur(2px);
}

.mapboxgl-control-container {
  position: absolute !important;
  top: 50px !important;
  left: 5px !important;
}

.mapboxgl-ctrl-attrib {
  opacity: 0 !important;
  display: none !important;
}

.shadow-bg {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.6) 0,
    rgba(0, 0, 0, 0.4) 15%,
    transparent 40%
  );
}

.nav-flexend {
  min-width: 110px !important;
}

.dealcard {
  background: #ffffff;
  box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.04);
  border-radius: 12px !important;
  border: none !important;
}

.poiinfo,
.circuitinfo {
  p {
    font-size: clamp(16px, 1vw, 20px) !important;
  }
}

.circuitinfo {
  h2 {
    margin: 20px 0 20px 0;
    font-size: 23px;
  }
}

.fav-fixed {
  position: fixed;
  bottom: 0;
  right: -90px;
  z-index: 999;
  cursor: pointer !important;

  img:hover {
    transform: scale(1.1);
  }
}

.eventSwip {
  .swiper-wrapper {
    height: 100% !important;
  }
}

.story-card {
  position: relative;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;

  background-size: cover;
  background-color: black;
  cursor: pointer;

  .price {
    position: relative;

    width: fit-content;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: red;
      top: 50%;
      left: 0;
    }
  }

  .storyprofile-header {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 0;
    width: 96.3%;
    padding-left: 40px;
    padding-right: 30px;
    height: 200px;
    justify-content: space-between;
    align-items: center;

    .storyprofile-subcontainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .storyprofile-avatar {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      position: relative;
      top: -4px;
      object-fit: cover;
      cursor: pointer;
      z-index: 99999;
    }

    h6 {
      font-style: normal;
      font-weight: bold;
      font-size: clamp(16px, 1vw, 22px);
      color: #ffffff;
      position: relative;
      top: 0px;
      left: 10px;
      text-shadow: 2px 2px black !important;
    }

    .timeago {
      font-style: normal;
      font-weight: 500;
      position: relative;
      top: -4px;
      left: 10px;
      font-size: clamp(16px, 1vw, 22px);
      color: #dddddd;
    }
  }
}

.swiper-grid-arrows {
  margin-top: 30px;
  width: 48vw;
  display: flex;
  position: relative;
  z-index: 99999999;

  button {
    width: 40px;
    background: none !important;
    border: none !important;

    &:disabled {
      opacity: 0.4;
    }
  }
}

@media (max-width: 768px) {
  .swiper-grid-arrows {
    margin-top: 10px;
    position: relative;
    z-index: 99999;
  }

  iframe {
    body {
      overflow: hidden !important;
    }

    width: 100vw;
    position: relative;
    left: -15px;
  }

  .fav-fixed {
    position: absolute !important;
    top: 220px;
    left: 20px;
    z-index: 999;
    max-width: 300px;
    height: fit-content !important;
  }
}

@media (min-width: 1280px) and (max-width: 1400px) {
  .swiper-grid-arrows {
    margin-left: 85% !important;
  }
}

@media (min-width: 1440px) and (max-width: 1900px) {
  .swiper-grid-arrows {
    margin-left: 86% !important;
  }
}

@media (min-width: 1900px) {
  .swiper-grid-arrows {
    margin-left: 88% !important;
  }
}

.seedoeat {
  display: flex;
  flex-direction: row;
  gap: 21px;

  h6 {
    cursor: pointer;
    text-transform: capitalize !important;
    font-size: clamp(18px, 1vw, 20px) !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    line-height: 140% !important;

    margin: 12px 0 23px 0 !important;
  }
}

.arrowstory {
  font-family: Poppins;

  font-style: normal;
  font-weight: bold;
  font-size: clamp(18px, 1vw, 22px);
  width: 50px !important;
  height: 50px !important;

  img {
    width: 24px !important;
    height: 24px !important;
    color: rgb(0, 0, 0);
  }

  color: #ffffff !important;
  position: relative;
  z-index: 9999999;
  background-color: transparent !important;
  border: none !important;
  cursor: pointer;
  top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.tripTabs {
  display: flex;
  flex-flow: row;
  border-radius: 16px;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  position: relative;
  z-index: 98;

  background-color: #fcc100;

  .hidden {
    visibility: hidden !important;
    display: none !important;
  }

  .nav-link.active {
    padding: 22px 0px 0px 25px !important;
    margin-top: 0px !important;
    height: 85px !important;
    background: rgba(255, 223, 1, 0.5) !important;
    transition: all 0.2s;
    border-radius: 16px;
  }
}

.checkbox-tag- + .checked {
  cursor: pointer;
  display: inline-block;
  font-weight: 900;
  margin: 5px;
  padding: 5px;
  font-family: Poppins;
  text-transform: capitalize;
  width: 13.1vw;
  padding: 20px 0px;
  position: relative;
  font-size: clamp(9px, 0.7291666666666666vw, 18px);
  background: #fcc100 !important;
  border-radius: 12px;

  img {
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    right: 10px;
  }
}

.explore {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: unset;
}

// mobile styles
@media (max-width: 768px) {
  .explore {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.regionnameMarker {
  position: absolute;
  left: 50%;
  top: 50%;
  width: max-content;
  max-width: 12.625rem;
  transform: translate(-50%, -50%);
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2.625rem;
  opacity: 1;
  background-color: white;
  padding: 0.5rem 1rem;
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  border: 2px solid #239bab;

  max-height: 3.5rem;
}

.swiper-wrapper {
  gap: 1rem !important;
}

.khouribga {
  transform: translate(24%, -72%) !important;
}

.fquih-ben-saleh {
  transform: translate(-67%, 0%) !important;
}

.beni-mellal {
  transform: translate(24%, -179%) !important;
}
