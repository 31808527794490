.footer {
  background: #262626;
  width: 100%;
  height: 100%;
  padding: 77px 0 20px 5%;
  .download {
    width: 520px;
    display: flex !important;
    height: 51px;
    justify-content: flex-start;
    p {
      color: #fff;
      font-family: Poppins;
      font-size: 16.765px;
      font-style: normal;
      font-weight: 700;
      line-height: 25.148px; /* 150% */
      text-transform: capitalize;
    }
    .btns {
      display: flex !important;
      justify-content: space-between;
      padding: 5px;
      gap: 9px;
      a {
        cursor: pointer;
        img {
          width: 132px;
          height: 40px;
        }
      }
    }
  }
  .col-second {
    top: 20px !important;
  }

  a,
  a:visited,
  a:active {
    text-decoration: none;

    font-family: Poppins;
    font-style: normal;
    margin-bottom: 50px;
    font-weight: 500;
    font-size: clamp(12px, 1vw, 18px) !important;
    line-height: 140%;
    color: white;
  }

  .privacy {
    width: 150px !important;
    padding: 0 !important;
  }

  // clipPath: polygon(37% 5%, 30% 5%, 15% 9%, 7% 13%, 0% 18%, 0% 100%, 100% 100%, 100% 3%, 89% 6%, 83% 6%, 74% 10%, 61% 14%, 55% 14%, 48% 10%);
  .mountains {
    position: absolute;
    background: #262626;
    width: 100%;
    right: 0;

    z-index: -1;
  }

  hr {
    width: 95vw;
    background: white !important;
  }

  .row {
    width: 100%;
  }

  &-row0 {
    align-items: center;
    width: 100%;
  }

  &__col-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 31px;

    width: 80%;

    > div {
      width: 100%;
    }
  }

  &-row-1 {
    font-family: Poppins;
    font-size: clamp(14px, 1.1vw, 18px);
    display: flex;
    flex-direction: column !important;
    margin-right: 77px;
    margin-left: -61px;

    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      color: white;

      &:not(:last-child) {
        margin-bottom: 20px !important;
      }
    }
  }

  &-row-2 {
    font-family: Poppins;
    font-size: clamp(14px, 1.1vw, 18px);
    font-weight: bold;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-transform: capitalize;
    p {
      cursor: pointer !important;
    }

    .col-md-2 {
      cursor: pointer;
    }
  }

  &-row-3 {
    font-size: clamp(14px, 1.1vw, 18px);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;

    line-height: 140%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    padding-left: 40px;

    div {
      display: flex;
      flex-direction: row;
      gap: 16px;
      justify-content: center;
      .footer-row__img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }
    a {
      margin-bottom: 0px !important;
    }
  }

  .logo {
    width: 100%;
  }

  .logom {
    width: 200%;
  }

  &-row-4 {
    font-family: Poppins;
    font-style: normal;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    font-weight: 500;
    width: 100% !important;
    font-size: clamp(12px, 1vw, 18px);
    line-height: 140%;
    color: white;
  }
}

@media (max-width: 768px) {
  .footer-shape{
    bottom: -29rem !important;
  }
  .footer {
    position: relative;
    margin: 0px;
    padding: 0px;
    width: 100vw;
    // left: -15px;
    padding-bottom: 100px;

    &__col-1 {
      margin: 0px !important;
      gap: 20px !important;
      width: 100%;
      justify-content: center;
      padding-left: 30px;

      > div {
        width: fit-content;
      }
    }

    .logom {
      width: 80px;
    }

    .mountains {
      position: absolute;
      background: #262626;
      width: 150vw;
      right: 0;
      z-index: 100;
      padding: 80px 0 0 20px;
      margin-top: -80px;
      z-index: 99999;
    }

    &-row0 {
      display: flex;
      width: 50%;
      justify-content: center;
      padding-top: 42px;
      .footer__col-1 {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
      }

      .col-md-1 {
        width: 100%;
      }
    }

    .download {
      width: 80vw;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-end;
      margin-top: 24px;
    }

    &-row-1 {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: white;
      gap: 20px;
      margin-top: 36px;
      margin-bottom: 36px;
      word-break: break-all;
      margin-right: unset;

      .footer-row__img {
        margin-right: 20px;
      }

      .desc {
        width: 169px;
        color: #fff;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 25.2px; /* 157.5% */
        word-break: keep-all;
      }
      .infos {
        width: 100%;
        overflow-wrap: break-word;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 20px;
        .email {
          inline-size: 169px;
        }
      }
    }

    .footer-row-3 {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
      margin-top: 52px;

      .col-second {
        position: relative;
        left: 2px;
      }
    }

    .footer-row-2 {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      gap: 10px;
      font-size: 15px;
      margin-top: 36px;
      cursor: pointer;
      align-self: flex-start;
      // padding-left: 40px;
    }

    .contact-section {
      width: 105%;
      justify-content: center;
      color: white;
      display: flex;
      margin-left: 15px;
      margin-top: 12px;
      margin-bottom: 12px;

      .col-md-6 {
        font-size: 15px;
        font-family: Poppins;
        font-weight: 500;
        width: 120px;
      }

      .col-md-2 {
        font-size: 15px;
        font-family: Poppins;
        font-weight: 500;

        width: 140px;
      }
    }

    .copyrightFooter {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      padding-left: 16px;
      padding-right: 16px;

      &-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        p {
          color: #fff;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 23.47px; /* 146.687% */
        }
      }

      &-contact {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 44px;

        p {
          color: #fff;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 23.47px; /* 146.687% */
        }
      }
    }

    .contact-section-center {
      width: 105%;
      justify-content: center;
      display: flex;
      color: white;
      text-align: center;

      .col1 {
        width: 100%;

        margin-left: 25px;
        margin-bottom: 24px;
      }

      .col2 {
        width: 340px;
        margin-left: 25px;
        margin-bottom: 40px;
      }
    }
  }

  .footer .logo {
    width: 45px !important;
  }
}
