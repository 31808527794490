.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.0001fr;
  grid-gap: 20px;
  height: 100%;
  font-family: Poppins;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);





  .fav {
    width: 32px !important;
    height: 32px !important;
    position: relative;
    display: block;
  }
}

.image-grid {
  .title {

    margin-left: 20px;
    line-height: 130%;
  }


}

// The big one
.item2 {
  border-radius: 20px;
  grid-column: 3 / 5;
  grid-row: 1 / 3;
  height: 100%;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);


}



// Small one
.item {
  border-radius: 20px;
  grid-column: 1/3;
  height: 100%;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);


}

.box {
  width: 100%;
  height: 100%;
  position: relative;

  span {
    display: block;
    width: 100%;
    height: 100%;

    img {
      border-radius: 20px !important;

    }
  }


  .img1,
  .img2,
  .img3 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }



  .img3 {
    max-height: 100%;
  }




  .shadow-bg {
    background: rgb(0, 0, 0);
    background: $image-shadow;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 117px;
    min-width: 100%;
    z-index: 1;
    border-radius: 22px;
  }

  .titlewrapper {
    position: absolute;
    bottom: 10px;
    z-index: 999999;
    padding: 10px 0px 10px 0px;

    width: 90%;

    .title {
      width: 92% !important;
      font-size: clamp(10px, 1.3vw, 22px) !important;
      font-weight: 100;
    }

    .distance {
      padding-left: 20px;
      font-size: 12px;
      color: #FFDF01;
      margin: 0 !important;
    }
  }


  .fav {
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 99999;
    display: flex;
    justify-content: flex-end;
  }


}



/*
  Wall
  */

.grid-wall {
  max-width: 93.5rem;
  margin: 0 auto;
  padding: 0 2rem;
  overflow: hidden;
  text-align: center;
  margin-top: 30px;
  position: relative;

  p {
    margin-bottom: 30px !important;
    width: 70%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .btn {
    display: inline-block;
    font: inherit;
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    cursor: pointer;
    outline: none;
  }


  .visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  /* Gallery Section */

  .gallery {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 3rem;
    overflow-y: hidden;
  }

  .gallery-item {
    position: relative;
    flex: 1 0 22rem;
    margin: 1rem;
    color: #fff;
    cursor: pointer;
    // max-width: calc(100% / 4);
  }

  .gallery-item:hover .gallery-item-info,
  .gallery-item:focus .gallery-item-info {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .gallery-item-info {
    display: none;
  }

  .gallery-item-info li {
    display: inline-block;
    font-size: 1.7rem;
    font-weight: 600;
  }

  .gallery-item-likes {
    margin-right: 2.2rem;
  }

  .gallery-item-type {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2.5rem;
    text-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
  }

  .fa-clone,
  .fa-comment {
    transform: rotateY(180deg);
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @supports (display: grid) {


    .gallery {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
    }

    .gallery-item,
    .gallery {
      width: auto;
      margin: 0;
    }
  }





  @media (min-width: 1000px) and (max-width:1280px) {
    .tricky {
      margin-top: -10vh !important;
      height: 33.5vh !important;
    }

    .modified {
      width: 60vh !important;

      img {
        width: 52.5vh !important;
      }
    }

    .bottom-item {
      height: 16vh !important;
    }
  }

  .bottom-item {
    height: 19vh
  }

  .modified {
    width: 80vh;

    img {
      width: 61.9vh;
    }
  }

  .item {
    position: relative;

  }

  &__img {
    transition: all .4s;
    object-fit: cover !important;
    width: 100%;
    height: 100%;
    position: relative;


  }
}

@media (min-width: 1600px) {
  .wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.01fr;

    .img1,
    .img2 {
      height: 310px !important;
    }

    .img3 {
      height: 640px;
    }
  }
}

@media (min-width: 770px) and (max-width: 1559px) {
  .wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.01fr;

    .box:not(:last-child) {
      height: 240px;
    }

    .img1,
    .img2 {
      height: 240px;
    }

    .img3 {
      height: 500px;
    }
  }

}

@media (max-width: 768px) {
  .swiper-container {
    height: 400px !important;
  }

  .wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .img1,
    .img2 {
      height: 300px;
    }
  }

  .swiper-slide {
    height: 70% !important;
  }

  .img1,
  .img2 {
    height: 120px !important;
  }

  .box {

    .img1,
    .img2,
    .img3 {
      height: 100%;
      max-height: 100%;
    }



  }

}