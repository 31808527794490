.trippage-swiper {
    font-family: Poppins;

    .margin-l-10 {
        margin-left: 10px;
    }

    .number-trip {
        background-color: #FCC100;
        width: 25px;
        display: flex;
        justify-content: center;
        border-radius: 60px;
    }

    .col-w-50 {
        button {
            background: none;
            border: none;
            width: 30px;
            height: 24px;
        }
    }

    .col-w-56 {
        p {
            font-family: Poppins;
            font-weight: 500;
            font-size: 18px;
        }
    }

    .col-w-160 {
        .span-1 {
            font-family: Poppins;
            font-size: 16px;
            opacity: 0.24;
        }
    }

    .steps {
        background: #FCC100;
        border-radius: 12px 0px 0px 0px;
        min-width: 55px;
        height: 24px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        padding-left: 10px;
        padding-right: 10px;
        font-family: Poppins;
        font-weight: 500;
        font-size: clamp(12px, 0.41vw, 18px);
    }

    .card {

        height: 300px;
        background: #FFFFFF;
        box-shadow: 0px -1px 12px 0px rgba(0, 0, 0, .1) !important;
        border-radius: 12px;
        border: none;
        font-family: inherit;

        .card-title {
            font-size: clamp(13px, 1.9vw, 16px) !important;
            font-weight: bold
        }

        .card-text {
            font-size: clamp(12px, 1.9vw, 12px) !important;
        }
    }

    hr {
        border: 1px solid #729ED9;
        width: 170px
    }

    .kms {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        right: 5px;
        z-index: 99999999 !important;
        font-family: Poppins;
        font-weight: 500;
        font-size: clamp(12px, 0.625vw, 18px);
    }
}


@media (max-width: 768px) {
    .trippage-swiper {
        &:not(:first-child) {
            margin-top: -60px;
        }

        .col-w-50 {
            width: 50px;
        }

        .col-w-56 {
            width: 56px;
            padding-top: 9px;

            p {
                font-size: 9px
            }
        }

        .col-w-160 {
            width: 160px;

            .span-1 {
                font-size: 9px
            }

            .span-2 {
                font-size: 12px
            }
        }

        .steps {

            font-size: 10px;
        }

        .card {

            .card-body {
                padding: 10px 0 0 5px;


            }



        }

        hr {
            width: 100px;
            z-index: 1;
            position: relative;
            bottom: 36px;
            left: 30px;
        }

        .kms {
            height: 80%;
            margin-left: 59px;
            font-size: 7px;
        }
    }
}

.collapseu {
    width: 96.7%;
    margin-top: -36px;
    position: relative;
    z-index: 99999999999;

    h1 {
        color: black !important;
    }

    h3 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 18px !important;
        color: #000000;
        position: relative;
        right: 15.4px;
    }
}