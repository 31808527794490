.fixed-bottom {
    position: fixed !important;
    border: 0;
    border-radius: 24px;
    border-top: 0;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    height: 91px;
    justify-content: center;
    width: 100%;
    max-width: 100vw;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.4);

}

.activeContainer {
    background: #ffffff;
    border-bottom: 1px solid #000000;
    border-radius: 0 0 40px 40px;
    border-top: 0;
    height: 70px;
    margin-bottom: 25px;
    width: 100%;


    &::before,
    &::after {
        bottom: 55px;
        content: "";
        height: 35px;
        position: absolute;
        width: 30px;

        z-index: 99;
    }

}

.bottom {

    width: 100px;

    .nav-link {
        margin: 0 !important;
    }

    a {
        font-family: Poppins;
        font-weight: 100;
        font-size: 11px !important;
        color: #FFFFFF !important;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 5px;
    }
}

.fixed-bottom .active {
    img {
        filter: saturate(3);
    }

}


.mobilemenu img {
    margin: 10px 0 0 0;
}

@media (min-width: 390px) and (max-width: 414px) {
    .map-mobile .modal {
        width: 100% !important;
        height: 100% !important;
        padding: 0px !important;
    }

    .map-mobile .modal .modal-body canvas {
        width: 100% !important;
        height: 100vh !important;
    }


    .map-mobile .modal-body {
        width: 100%;
        height: 100%;
        padding: 0 !important;
    }


    .map-mobile .modal-container {
        width: 100%;
        height: 100%
    }

    .fixed-bottom {
        width: 100%;
    }

    .mobilemenu {
        margin: -10px 0 0 3.2vw;
    }

    .menuContainer {
        width: 22vw;
    }
}


@media (min-width: 768px) {
    .bottom-nav-container {
        display: none !important;
    }
}