.pretty-title {
    .container {
        margin-left: -50px;
        margin-top: 30vh;
        width: 25vw
    }

    .lsc-actions {
        margin-top: 50px;
    }

    .actions {
        margin-top: 50px;
    }

    .arrows {
        background-color: transparent;
        background-repeat: no-repeat;
        border: 0;
        justify-content: flex-end;
        margin-left: 10px !important;
        width: 5vw;
    }

    .arrows:hover {
        background-color: transparent;
        transform: rotate(330deg);
    }

    .discover {
        background: $background-pretty-btn;
        border: 0;
        border-radius: 10px;
        color: #ffffff;
        font-family: Poppins;
        font-size: clamp(12px, 1.1vw, 18px);
        font-weight: map-get($font-weights, "medium");
        height: 3vw;
        justify-content: flex-start;
        width: 11vw;

        &:hover {
            background: $background-pretty-btn;
        }
    }
}